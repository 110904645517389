.data-table {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra tanto el título como la tabla */
  width: 100%;
  margin-bottom: 20px;
}

.data-table h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  text-align: center;
}

.data-table table {
  width: 70%; /* La tabla ocupa el 70% del ancho */
  margin: 0 auto;
  border-collapse: collapse;
  table-layout: fixed; /* Asegura que las columnas tengan un ancho fijo */
}

.data-table th, .data-table td {
  padding: 4px 8px; /* Reducir el padding para disminuir la altura */
  border: 1px solid #ddd;
  text-align: left;
}

.data-table td:first-child {
  width: 25%; /* La columna de las claves será más estrecha */
}

.data-table td:last-child {
  width: 75%; /* La columna de los valores será más ancha */
}

.table-wrapper {
  margin-bottom: 40px; /* Espacio entre la tabla y otros elementos */
}

.additional-info {
  margin-top: 20px;
  font-size: 1.1em;
}

.additional-info p {
  margin: 5px 0;
}
