/* styles.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Espacio entre las filas */
  padding: 20px; /* Espacio entre el borde de la pantalla y los elementos */
  height: 100vh; /* 100% de la altura de la ventana del navegador */
  width: 100%; /* 100% del ancho de la ventana del navegador */
}

.dashboard-row {
  display: flex;
  justify-content: space-around;
  padding-top: 10px; /* Espacio entre los bordes de las filas */
}
